import { useContext, useEffect, useMemo, useState } from 'react'
import { SplitIOContext } from 'shared/components/split.io'
import type Consts from 'consts'
import rollbar from 'shared/utils/logging/integrations/rollbar'
import useSplitAttributes from './useSplitDefaultAttributes'

export type AvailableSplits = (typeof Consts.SPLITS)[keyof typeof Consts.SPLITS] | 'test-split'

export const SPLIT_LOAD_TIMEOUT = 5000

const stickyTreatment: Partial<Record<string, boolean>> = {}

type UseSplitOptions<T> = {
  attributes?: SplitIO.Attributes
  defaultTreatment?: T | null
  positiveTreatments?: string[]
  sticky?: boolean
}

type ReturnTypeWithDefaultTreatment<T> = boolean | T

/**
 * Fetch a split's treatment for a given set of attributes
 * Options available:
 * - `attributes`: non-user attributes to match an intended treatment rule
 * - `defaultTreatment`: return value for when the split is loading or unavailable
 *   - `null` is the default
 * - `positiveTreatments`: treatments which indicate the split is enabled
 *   - the default enabled treatment is `"on"`
 * - `sticky`: stick to first treatment value when split is loaded
 *   - note: if attributes change, the split will be re-evaluated
 */
function useSplit<T = null>(
  splitName: AvailableSplits,
  {
    attributes,
    defaultTreatment = null,
    positiveTreatments = ['on'],
    sticky = false,
  }: UseSplitOptions<T> = {}
): ReturnTypeWithDefaultTreatment<T> {
  const [isEnabled, setIsEnabled] = useState<boolean | null>(null)
  const { client } = useContext(SplitIOContext)

  const defaultAttributes = useSplitAttributes()
  const treatment = client?.getTreatment(splitName, {
    ...defaultAttributes,
    ...attributes,
  })

  const stickyTreatmentKey = useMemo(
    () => `${splitName}-${JSON.stringify(attributes)}`,
    [attributes, splitName]
  )

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>

    if (!treatment) {
      timeout = setTimeout(() => setIsEnabled(false), SPLIT_LOAD_TIMEOUT)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [treatment])

  useEffect(() => {
    if (treatment) {
      if (sticky && stickyTreatment[stickyTreatmentKey] != null) {
        return
      }
      setIsEnabled(positiveTreatments.includes(treatment))
    }
  }, [isEnabled, positiveTreatments, splitName, sticky, stickyTreatmentKey, treatment])

  useEffect(() => {
    if (sticky && isEnabled != null && stickyTreatment[stickyTreatmentKey] == null) {
      stickyTreatment[stickyTreatmentKey] = isEnabled
    }
  }, [isEnabled, splitName, sticky, stickyTreatmentKey, treatment])

  if (window.SPLIT_TREATMENTS) {
    if (typeof window.SPLIT_TREATMENTS[splitName] === 'boolean') {
      return window.SPLIT_TREATMENTS[splitName]
    } else {
      rollbar.logError(`Split override with no treatment not found for ${splitName}`)
    }
  }

  return (stickyTreatment[stickyTreatmentKey] ??
    isEnabled ??
    defaultTreatment) as ReturnTypeWithDefaultTreatment<T>
}

export default useSplit
