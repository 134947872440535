import { get, reduce } from 'lodash'
import { calcCrow } from 'shared/components/Maps/Utils'

export const CURRENCIES = {
  USD: {
    id: 'USD',
    name: 'Dollars',
    symbol: '$',
    toString() {
      return this.id
    },
  },
  EUR: {
    id: 'EUR',
    name: 'Euros',
    symbol: '€',
    toString() {
      return this.id
    },
  },
}

export const DEFAULT_CURRENCY = CURRENCIES.USD

export const getCurrencyClass = (currency) => ` currency-${currency?.toLowerCase()}`

export function getCurrencySymbol(currency) {
  return get(CURRENCIES, currency, DEFAULT_CURRENCY).symbol
}

export const LANGUAGES = {
  EN: {
    id: 'en',
    name: 'English',
    toString() {
      return this.id
    },
  },
  DE: {
    id: 'de',
    name: 'Deutsch',
    toString() {
      return this.id
    },
  },
}

const defaultLanguage = (window.ENV?.DEFAULT_LANGUAGE || 'en').toUpperCase()
export const DEFAULT_LANGUAGE = LANGUAGES[defaultLanguage]

export const DISTANCE_UNITS = {
  MI: {
    id: 'mi',
    name: 'Miles',
    unitSystem: 'IMPERIAL',
    toString() {
      return this.id
    },
  },
  KM: {
    id: 'km',
    name: 'Kilometers',
    unitSystem: 'METRIC',
    toString() {
      return this.id
    },
  },
}

const defaultDistanceUnit = (window.ENV?.DEFAULT_DISTANCE_UNIT || 'mi').toUpperCase()
export const DEFAULT_DISTANCE_UNIT = DISTANCE_UNITS[defaultDistanceUnit]

export function getDistanceUnitName(distanceUnit, { plural = true } = { plural: true }) {
  const { name } = get(DISTANCE_UNITS, distanceUnit?.toUpperCase(), DEFAULT_DISTANCE_UNIT)

  if (plural) {
    return name
  } else {
    return name.slice(0, -1)
  }
}

// size of mile in kilometers
const MILE_IN_KM = 1.6093440006146922

function convertMiToKm(miles, precision = 1) {
  const exp = 10 ** precision
  const result = Math.round(miles * MILE_IN_KM * exp) / exp
  return result.toFixed(precision)
}

function convertKmToMi(kilometers, precision = 1) {
  const exp = 10 ** precision
  const result = Math.round((kilometers / MILE_IN_KM) * exp) / exp
  return result.toFixed(precision)
}

export function isUnitKm(unit) {
  return unit === DISTANCE_UNITS.KM.id
}

export function distanceToFE(distance, unit, precision = 2) {
  return isUnitKm(unit) ? convertMiToKm(distance, precision) : distance
}

export function distanceToBE(distance, unit, precision = 2) {
  return isUnitKm(unit) ? convertKmToMi(distance, precision) : distance
}

export function moneyToFE(amount, unit, precision = 2) {
  // convert `per mile` to `per kilometers`
  // re-use convertKmToMi because calculation is the same
  return isUnitKm(unit) ? convertKmToMi(amount, precision) : amount
}

export function moneyToBE(amount, unit, precision = 2) {
  // convert `per kilometer` to `per mile`
  // re-use convertMiToKm because calculation is the same
  return isUnitKm(unit) ? convertMiToKm(amount, precision) : amount
}

export function formatDistance(miles, unitParam = 'mi', precision = 1) {
  const unit = get(DISTANCE_UNITS, unitParam.toUpperCase(), DEFAULT_DISTANCE_UNIT)

  let distance = ''

  if (unit === DISTANCE_UNITS.KM) {
    distance = convertMiToKm(miles, precision)
  } else {
    const exp = 10 ** precision
    const result = Math.round(miles * exp) / exp
    distance = result.toFixed(precision)
  }

  return `${distance} ${unit}`
}

export function getUnitSystem(unit) {
  return get(DISTANCE_UNITS, unit?.toUpperCase(), DEFAULT_DISTANCE_UNIT).unitSystem
}

export const COUNTRIES = {
  US: {
    anchor: {
      lat: 37.0902,
      lng: -95.7129,
    },
    currency: CURRENCIES.USD,
    distanceUnit: DISTANCE_UNITS.MI,
    language: LANGUAGES.EN,
    name: 'United States',
  },
  DE: {
    anchor: {
      lat: 51.1657,
      lng: 10.4515,
    },
    currency: CURRENCIES.EUR,
    distanceUnit: DISTANCE_UNITS.KM,
    language: LANGUAGES.DE,
    name: 'Germany',
  },
}

export const DEFAULT_COUNTRY = COUNTRIES.US

export function getDefaultCountry({ lat, lng }) {
  if (lat && lng) {
    return reduce(COUNTRIES, (closest, country) => {
      const countryDistance = calcCrow(lat, lng, country.anchor.lat, country.anchor.lng)
      const closestDistance = calcCrow(lat, lng, closest.anchor.lat, closest.anchor.lng)
      if (countryDistance < closestDistance) {
        return country
      }
      return closest
    })
  } else {
    return DEFAULT_COUNTRY
  }
}
