import auth0Service from 'web-client/utils/auth0'
import sessionService from 'web-client/utils/session'
import type { Environment } from 'react-relay'
import logging from 'shared/utils/logging'
import userService from 'shared/utils/user'
import { oauthRefreshRequest } from './oauthRefreshRequest'

export type OauthTokenResponse = {
  expires_in: string
  access_token: string
  error: string
  error_description: string
  refresh_token: string
}

export type BearerToken = OauthTokenResponse['access_token'] | null

let refreshPromise: Promise<BearerToken> | null

function resetPromise() {
  setTimeout(() => (refreshPromise = null), 0)
}

export const refreshTokenIfNeeded = (relayEnvironment: Environment, force = false) => {
  logging.logInfo('[auth-refresh] refreshTokenIfNeeded', {
    force,
    isLoggingOut: sessionService.isLoggingOut,
    token: `...${sessionService.getBearerToken()?.slice(-5)}`,
    expiresAt: sessionService.getExpiresAt(),
  })

  if (refreshPromise == null) {
    refreshPromise = new Promise<BearerToken>((resolve, reject) => {
      // This set timeout is used so the promise
      // returns to the variable `refreshPromise`
      // before the promise body executes :)
      // That way if the promise body resolves
      // immediately (in the last case of the if block below)
      // setting the refreshPromise to null will actually work.

      if (sessionService.isLoggingOut) {
        resetPromise()
        resolve(sessionService.getBearerToken())
        return
      }

      function handleError(error: Error) {
        logging.logWarning('[auth-refresh] error refreshing token', {
          error,
          token: `...${sessionService.getBearerToken()?.slice(-5)}`,
        })
        resetPromise()
        void userService
          .logoutWithError(
            relayEnvironment,
            'Please re-enter your login information to keep using Swoop.',
            error
          )
          .catch(() => reject(error))
          .then(() => reject(error))
      }

      function handleSuccess(
        tokens: {
          expires_in: OauthTokenResponse['expires_in']
          bearer: BearerToken
          refresh: string
        } | null
      ) {
        resetPromise()
        if (tokens && tokens.bearer && tokens.refresh) {
          sessionService.setBearerToken(tokens.bearer)
          sessionService.setRefreshToken(tokens.refresh)
          sessionService.setExpiresAtFromExpiresIn(tokens.expires_in)
        }
        resolve(sessionService.getBearerToken())
      }

      if (sessionService.shouldRefresh()) {
        logging.logInfo('[auth-refresh] Token Expired, Refreshing', {
          expiresAt: parseInt(sessionService.get('expires_at') ?? '', 10),
        })
        if (auth0Service.isAuthenticated()) {
          auth0Service
            .refresh()
            .then((token) => {
              resetPromise()
              resolve(token)
            })
            .catch(handleError)
        } else if (sessionService.hasExpiresAt()) {
          // if `shouldRefresh` is `true`, we do not have `expiresAt`
          // this block can probably be removed
          logging.logWarning('[auth-refresh] unexpected expiration set')
          oauthRefreshRequest().then(handleSuccess).catch(handleError)
        } else {
          logging.logWarning('[auth-refresh] setting to non-auth0 token')
          resetPromise()
          resolve(sessionService.getBearerToken())
        }
      } else if (force) {
        oauthRefreshRequest().then(handleSuccess).catch(handleError)
      } else {
        handleSuccess(null)
      }
    })
  }
  return refreshPromise
}
