import { JobStatus as JobStatusEnum } from 'graphql/enums'
import { ORDERED_STATUSES } from 'consumer-mobile-web/constants'
import type { JobStatus } from 'consumer-mobile-web/constants'
import logging from 'shared/utils/logging'

enum DisplayStatus {
  MatchingWithProvider = 'Matching with a provider..',
  Assigned = 'Assigned',
  EnRoute = 'En Route',
  OnSite = 'On Site',
  Towing = 'Towing',
  TowDestination = 'At Destination',
  Completed = 'Complete',
  Canceled = 'Canceled',
  Referred = 'Pay Provider Directly',
}

export const getDisplayStatus = (status: JobStatus): JobStatus | DisplayStatus => {
  switch (status) {
    case JobStatusEnum.Created:
    case JobStatusEnum.Pending:
    case JobStatusEnum.Submitted:
    case JobStatusEnum.Collecting:
    case JobStatusEnum.AutoAssigning:
      return DisplayStatus.MatchingWithProvider
    case JobStatusEnum.Accepted:
    case JobStatusEnum.Assigned:
    case JobStatusEnum.Dispatched:
      return DisplayStatus.Assigned
    case JobStatusEnum.EnRoute:
      return DisplayStatus.EnRoute
    case JobStatusEnum.OnSite:
      return DisplayStatus.OnSite
    case JobStatusEnum.Towing:
      return DisplayStatus.Towing
    case JobStatusEnum.TowDestination:
      return DisplayStatus.TowDestination
    case JobStatusEnum.Completed:
      return DisplayStatus.Completed
    case JobStatusEnum.Canceled:
    case JobStatusEnum.GOA:
    case JobStatusEnum.Unsuccessful:
      return DisplayStatus.Canceled
    case JobStatusEnum.ReassignRequested:
    case JobStatusEnum.GOARequested:
    case JobStatusEnum.UnsuccessfulRequested:
    case JobStatusEnum.Referred:
      return DisplayStatus.Referred
    default:
      return status
  }
}

declare let window: Window

// TODO: this function needs to be refactored https://swoopme.atlassian.net/browse/ENG-53578
export const getJobId = () => {
  // survey uses gon?.ssid, everything else uses the last part of the url
  const jobId = window?.gon?.ssid || window.location.pathname.split('/').pop() || ''
  if (!jobId) {
    logging.logWarning(`jobId value not set`, {
      topic: 'Missing Job Id',
    })
  }
  return jobId
}

export const getUUID = () => {
  const { search } = window.location
  return new URLSearchParams(search).get('uuid') || null
}

export const getMobileAuthorization = () => {
  const uuid = getUUID()
  if (uuid) {
    return `Bearer job:${getUUID()}`
  }
  return null
  // return 'Bearer ...'
}

const INDEX_NOT_FOUND = -1
export function isBeforeStatus(status: JobStatus, targetStatus: JobStatus, inclusive = false) {
  const currentStatusIndex = ORDERED_STATUSES.indexOf(status)
  const targetStatusStatusIndex = ORDERED_STATUSES.indexOf(targetStatus)
  if (currentStatusIndex === INDEX_NOT_FOUND || targetStatusStatusIndex === INDEX_NOT_FOUND) {
    return false
  }

  if (inclusive) {
    return currentStatusIndex <= targetStatusStatusIndex
  } else {
    return currentStatusIndex < targetStatusStatusIndex
  }
}

export function isAfterStatus(status: JobStatus, targetStatus: JobStatus, inclusive = false) {
  const currentStatusIndex = ORDERED_STATUSES.indexOf(status)
  const targetStatusStatusIndex = ORDERED_STATUSES.indexOf(targetStatus)
  if (currentStatusIndex === INDEX_NOT_FOUND || targetStatusStatusIndex === INDEX_NOT_FOUND) {
    return false
  }

  if (inclusive) {
    return currentStatusIndex >= targetStatusStatusIndex
  } else {
    return currentStatusIndex > targetStatusStatusIndex
  }
}
