import datadog from 'shared/utils/logging/integrations/datadog'
import retry from './retry'

if (!window.HTMLDialogElement) {
  datadog.logWarning('Polyfill for HTMLDialogElement', {})
  void retry(() => import('dialog-polyfill/dist/dialog-polyfill.css'))
  void retry(() => import('dialog-polyfill'))
}

// polyfill for Element.closest()
if (!Element.prototype.matches) {
  datadog.logWarning('Polyfill for matches', {})
  Element.prototype.matches =
    // @ts-expect-error -- msMatchesSelector exists on some microsoft browsers
    // eslint-disable-next-line @typescript-eslint/unbound-method -- only uses it if it's the only option due to outdated browser
    Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector
}
if (!Element.prototype.closest) {
  datadog.logWarning('Polyfill for closest', {})
  Element.prototype.closest = function closest(s: keyof HTMLElementTagNameMap) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias -- assigns el to this so it can be drilled into
    let el: Element = this
    do {
      if (el.matches(s)) {
        return el
      }
      el = (el.parentElement || el.parentNode) as Element
    } while (el !== null && el.nodeType === 1)
    return null
  }
}
